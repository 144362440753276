import React from 'react';

function MissingCashBack() {
  return (
    <div className="bg-base-200 rounded-lg shadow-lg p-6 text-left">
      <h2 className="text-2xl font-bold mb-4">Missing Cash Back?</h2>
      <p className="text-base-700 mb-4">
        If you're missing cash back from your recent purchase, don't worry! Here are some steps you can take to get it resolved:
      </p>
      <ul className="list-disc list-inside mb-4 pl-4 text-left">
        <li>Check your account balance to make sure the cash back wasn't already credited.</li>
        <li>Check the terms and conditions of the offer to make sure you're eligible.</li>
        <li>Contact customer support for the cash back provider to get more information.</li>
        <li>If you've tried all of these steps and still haven't received your cash back, please contact us and we'll be happy to help!</li>
        <li>For more information on how to resolve missing cash back issues, please visit our website.</li>
      </ul>
      <p className="text-base-700">
        If you've tried all of these steps and still haven't received your cash back, please contact us and we'll be happy to help!
      </p>
    </div>
  );
}

export default MissingCashBack;
