import React from "react";

const PrivacyPolicy = () => {
    document.title = "Privacy Policy";
    return (
        <div className="px-6 py-4 md:px-40 md:py-10">
            <div className="text-center">
                <h1 className="font-semibold text-2xl md:text-2xl mb-6 md:mb-10">
                    PRIVACY POLICY
                    <hr/>
                </h1>
            </div>
            <p className="font-bold mb-3">This Privacy Policy was last updated on 06/12/2023</p>
            <p className="mb-3">This privacy policy applies to www.Markdowntoday.com owned and operated by Carociba Inc.
            (collectively, &quot;We&quot;, &quot;Our&quot;, &quot;Us&quot;). This privacy policy describes how Carociba Inc. collects and uses the
            personally identifiable information you provide on our Web site: www.Markdowntoday.com, browser
            extensions and mobile applications. It also describes the choices available to you regarding our use of
            your personally identifiable information and how you can access and update this information.</p>

            
            <p className="font-bold mb-3">1. Information Collection and Sharing</p>
            <p className="font-bold mb-3 italic">Personally Identifiable Information (PII)</p>
            
            <p className="mb-3">Markdowntoday.com is committed to protecting users&#39; privacy. Users are not required to provide any
            Personally Identifiable Information in order to use this site.</p>

            <p className="mb-3">However, visitors wishing to take advantage of our special offers may be asked to provide their email
            address and as much of the following information as they are willing to provide name, gender, age, and
            personal interests. Contest winners will be required to provide the information necessary to deliver their
            prizes to them.</p>

            <p className="mb-3">We will share your Personally Identifiable Information with third parties only in the ways that are
            described in this privacy policy. We do not sell your Personally Identifiable Information to third parties.</p>

            <p className="mb-3">We may provide your personal information to companies that provide services to help us with our
            business activities such as our Live Chat or offering customer service. These companies are authorized to
            use your personal information only as necessary to provide these services to us.</p>
            
            <p className="font-bold mb-3 italic">Promotional Mailings</p>
            
            <p className="mb-3">During the registration process, postal address information is collected and saved in your
            Markdowntoday.com account. This may be used to send periodic promotions, such as postcards or
            other mailings from Markdowntoday.com. Your address will not be given or sold to any third parties nor
            will Markdowntoday.com send any Third-Party promotional mailings.</p>

            <p className="font-bold mb-3 italic">Personally Identifiable Information (Applies only to Cash Back Participants)</p>

            <p className="mb-3">Cash Back members will be required to provide their full names and mailing address for all payout
            methods in order for Markdowntoday.com to provide them with their payment.</p>
            <p className="mb-3">The payout methods are:</p>
            <p>Check</p>
            <p>PayPal</p>
            <p>Direct Deposit</p>
            <p>Venmo</p>
            <p>Zelle</p>
            <p className="mb-3">eGift Card</p>

            
            <p className="mb-3">Direct Deposit account numbers will be immediately encrypted using 256-bit encryption.</p>
            
            <p className="mb-3">In the event that a user opens a cash back inquiry, Markdowntoday.com will send any relevant inquiry
            details to the retailers and the affiliate network on the user&#39;s behalf.</p>

            <p className="mb-3">Cash Back members in the United States receiving bonus payments in excess of $600 will be required to
            provide a social security number or Tax ID (TIN) as required by the Federal Government for tax purposes.
            Members in the EU countries will be required to provide their Citizenship number on the same purpose.</p>

            
            <p className="font-bold mb-3 italic">Browser Extensions</p>

            <p className="mb-3">Markdowntoday.com&#39;s browser extensions automatically collect certain information in order to function
            properly and to help us better understand how our products work on various devices and browsers.
            Information collected may include devices used, IP addresses, operating system, browsers, error logs,
            and other similar information. This allows us to improve your experience.</p>

            <p className="mb-3">On retailer websites, in order to award Cash Back and show coupons, we track browsing, transaction
            activity, and collect information on coupons used. You can opt out of sharing your shopping experience
            anytime on the settings page of the Markdowntoday.com browser extensions.</p>

            
            <p className="font-bold mb-3 italic">Mobile Apps</p>
            

            <p className="mb-3">Our mobile apps may share PII such as hashed information and device identifiers to third party service
            providers to facilitate your purchases, rewards, and special targeted offers and Cash Back rates.</p>

            
            <p className="font-bold mb-3 italic">Feedback Form</p>

            <p className="mb-3">We have an optional feedback/contact us form available that requires responders to provide their name
            and email address. People who do not want to provide their name and email address should avoid the
            feedback form.</p>

            
            <p className="font-bold mb-3 italic">Customer Testimonials</p>
            
            <p className="mb-3">We post customer testimonials on our website which may contain Personally Identifiable Information
            such as the customer name. Reviews are solicited via a Third-Party website from an unbiased sampling
            of our customers. When a review or testimonial is submitted to Markdowntoday.com directly, we obtain
            the customer&#39;s consent prior to posting their name and testimonial. If you wish to update or delete your
            testimonial, please contact us.</p>

            
            <p className="font-bold mb-3 italic">Blogs</p>
            
            <p className="mb-3">Our Website offers publicly accessible blogs/community forums. You should be aware that information
            you provide in these areas may be read, collected, and used by others who access them. To request
            removal of your Personally Identifiable Information from our blog or community forum, contact us.</p>

            
            <p className="font-bold mb-3 italic">Chat</p>
            
            <p className="mb-3">We use a chat feature to assist you with any questions you may have while using the site. If our chat
            feature is offline, we might collect your name and email address so that we may reply to your request.</p>

            
            <p className="font-bold mb-3 italic">Refer a friend-Third Party Personally Identifiable Information</p>
            
            <p className="mb-3">If you choose to use our referral service to tell a friend about our site, we will ask you for your friend&#39;s
            name and email address. We will automatically send your friend an email inviting him or her to visit the
            site. Carociba Inc. stores this information for the sole purpose of sending this email. We may send more
            than one email. Your friend may contact us to request that we remove this information from our
            database. Also, referees may see the referrer&#39;s e-mail address, and referrers will know whether referees&#39;
            account balances have reached the payout threshold. Further, referrers may also be shown a partial
            copy of the referee&#39;s email address.</p>

            
            <p className="font-bold mb-3 italic">Log files</p>
            
            <p className="mb-3">As is true of most websites, we automatically gather certain information that is stored in our servers log
            files. This information includes Internet protocol (IP) addresses, browser type, Internet service provider
            (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data.</p>
            <p className="mb-3">As needed, Markdowntoday.com may aggregate data collected from log files to demonstrate usage
            information from our website for prospective merchants.</p>

            
            <p className="font-bold mb-3 italic">Legal Disclaimer</p>
            
            <p className="mb-3">In certain situations, Carociba Inc. may be required to disclose personal data in response to lawful
            requests by public authorities, including to meet law enforcement requirements. We reserve the right to
            disclose users&#39; Personally Identifiable Information as required by law and when we believe that
            disclosure is necessary to protect our rights, protect your safety or the safety to others, investigate
            fraud, respond to a government request, and/or to comply with a judicial proceeding, court order, or
            legal process served on our website.</p>
            <p className="mb-3">If Carociba Inc. is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be
            notified via email and/or a prominent notice on our website, of any change in ownership, uses of your
            Personally Identifiable Information, and choices you may have regarding your Personally Identifiable
            Information.</p>
            <p className="mb-3">We may also disclose your Personally Identifiable Information to any other Third Party with your prior
            consent.</p>

            
            <p className="font-bold mb-3">2. Links to Third Party Websites</p>
            
            <p className="mb-3">This website publishes links to other sites that are not owned or controlled by Carociba Inc. Please be
            aware that Carociba Inc. is not responsible for the privacy practices of these sites.</p>
            <p className="mb-3">We encourage users to be aware that clicking special offers or coupons will redirect them to various
            merchant websites. It is important to read individual merchant&#39;s privacy policies before making
            purchases or entering any Personally Identifiable Information.</p>
            <p className="mb-3">These links may contain tracking parameters that tie the user&#39;s shopping activity back to the user&#39;s
            personal Markdowntoday.com account. Retailers or affiliate networks will report this activity to
            Markdowntoday.com, which Markdowntoday.com will then use to pay cashback. This tracking can be
            disabled by opting out.</p>

            
            

            
            <p className="font-bold mb-3">3. Cookies Policy</p>
            
            <p className="mb-3">We Markdowntoday.com and its partners use cookies or similar technologies.</p>

            
            <p className="font-bold mb-3 italic">What are cookies?</p>
            
            <p className="mb-3">Cookies are small text files that are stored on your device and provide us with the ability to track, store,
            and collect information.</p>

            
            <p className="font-bold mb-3 italic">What do our cookies do for you?</p>
            
            <ul className="list-disc md:ml-20 text-left mb-3">
                <li>Track your shopping transactions. When you make a purchase at a retailer&#39;s site, cookies provide
                the details for tracking your cash back activity. We learn when you have visited the site, if you
                have made a purchase, the validation of the purchase, or cancellation.</li>
                <li>Recognize that you have signed in so that you do not have to log into your account for a period
                of time</li>
                <li>Tell us how you came to our site and whether any referral bonuses apply</li>
                <li>Offer you deals based on your site behavior</li>
                <li>Help identify fraudulent activity</li>
                <li>Provide analytics and reporting</li>
                <li>Remember options you have selected for your account settings</li>
            </ul>

            
            <p className="font-bold mb-3 italic">What do Third Party cookies do for you?</p>
            
            <ul className="list-disc md:ml-20 text-left mb-3">
                <li>Third Party advertisers may display advertising on our site or manage our advertising on other
                sites</li>
                <li>Gather information about your activities on this site and other sites to provide you with
                advertising based on your browsing activities and interests. Retailers who you visit from
                Markdowntoday.com may place cookies on your device. Retailers you visit will have their own
                Cookies and Privacy Policies.</li>
                <li>Serve ads on our site based on past visits to our site</li>
                <li>We also use Third Party cookies to gather information for analytics/reporting</li>
            </ul>

            
            <p className="font-bold mb-3 italic">How do I control the use of cookies?</p>
            
            <ul className="list-disc md:ml-20 text-left mb-3">
                <li>You can control cookies at the browser level</li>
                <li>If you disable cookies, you will not earn Cash Back from Markdowntoday.com</li>
            </ul>

            
            <p className="font-bold mb-3 italic">How do I opt 0ut of cookies</p>
            
            <ul className="list-disc md:ml-20 text-left mb-3">
                <li>If you choose to opt-out of our cookie policy, the site won&#39;t be working as intended. Without the
                cookies we cannot track your shopping activity, and thus cannot pay you Cash Back. If you still
                choose to opt-out, click here. Users can also opt back in by clicking this same link above and
                then selecting &quot;Accept Cookies&quot; at the top of the page</li>
                <li>If you choose to opt-out of Third-Party Cookies placed by Google and its advertisers, click here</li>
                <li>If you wish to opt-out of other Third-Party advertisers, click here</li>
            </ul>

            
            <p className="mb-3 font-bold italic">Widgets</p>

            <p className="mb-3">Our Web site includes Social Media Features, such as the Facebook Like button. These Features may
            collect your IP address, which page you are visiting on our site, and may set a cookie to enable the
            Feature to function properly. Social Media Features and Widgets are either hosted by a Third Party or
            hosted directly on our Site. Your interactions with these Features are governed by the privacy policy of
            the company providing it.</p>

            
            <p className="font-bold mb-3 italic">4. Editing Your Personal Data/Choice to Opt Out</p>

            <ul className="list-disc md:ml-20 text-left mb-3">
                <li>You can make changes below to your Personal Data by accessing the Profile Page of your
                Markdowntoday.com account</li>
                <li>You can delete your personal data. Contact us if you wish to delete your account from our
                system. We will respond to you within a reasonable timeframe</li>
                <li>Upon request Markdowntoday.com will provide you with information about whether we hold
                any of your personal information</li>
                <li>You can access your personal account history/data on your account page. If you have any
                questions about accessing this data, cannot find data that you are interested in, or need
                assistance accessing this data in another format, please contact support</li>
            </ul>
            

            
            <p className="font-bold mb-3 italic">5. Managing your Email Subscriptions</p>

            <ul className="list-disc md:ml-20 text-left mb-3">
                <li>You can manage your email subscriptions on the Email Subscription page of your
                Markdowntoday.com account. This allows you to opt-in or opt-out from different email
                categories or unsubscribe from all communications in one click</li>
                <li>You can also unsubscribe from all communications by clicking the unsubscribe link on the
                bottom of each email or click here.</li>
            </ul>

            
            <p className="mb-3 font-bold">6. Security</p>

            <p className="mb-3">We follow generally accepted industry standards to protect the Personally Identifiable Information
            submitted to us, both during transmission and once we receive it. No method of transmission over the
            Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to use
            commercially acceptable means to protect your Personally Identifiable Information, we cannot
            guarantee its absolute security.</p>

            
            <p className="mb-3 font-bold">7. Data Retention</p>

            <p className="mb-3">We will retain your information for as long as your account is active or as needed to provide you
            services. If you wish to cancel your account or request that we no longer use your information to

            provide you services, contact support. However, we reserve the right to retain and use your information
            as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

            
            <p className="mb-3 font-bold">8. California Consumer Privacy Act (CCPA)</p>

            <p className="mb-3">Effective January 1, 2020, under the California Consumer Privacy Act (CCPA), Markdowntoday.com
            customers located in the state of California with questions or requests relating to their account status,
            including CCPA requests, should contact us via the Contact Us Form.</p>
            <p className="mb-3">Any requests not originating from members or non-members who are not current residents of California
            will not be fulfilled.</p>

            
            <p className="mb-3 font-bold">9. Transferring of Personal Data From Other Countries</p>

            <p className="mb-3">Markdowntoday.com is located in the United States. If you are using our site from another country, you
            are consenting to the transfer, storing, and processing of your Personally Identifiable Information by
            secure servers in the U.S. This site is subject to US laws. Please note that US laws regarding Personally
            Identifiable Information protection can differ from other countries.</p>
            <p className="mb-3">Markdowntoday.com takes protecting your Personally Identifiable Information very seriously. We will
            strive to resolve any issues or concerns you have regarding the use of your Personally Identifiable
            Information in a prompt and committed manner. Should you have any questions about our Privacy
            Policy please contact support.</p>

            
            <p className="mb-3 font-bold">10. Changes in this Privacy Policy</p>

            <p className="mb-3">If we decide to change our privacy policy, we will post and date those changes to this privacy policy, the
            home page, and other places we deem appropriate so that you are aware of what information we
            collect, how we use it, and under what circumstances, if any, we disclose it.</p>
            <p className="mb-3">We reserve the right to modify this privacy policy at any time, so please review it frequently. If we make
            material changes to this policy, we will notify you here, by email, or by means of a notice on this Site
            prior to the change becoming effective.</p>

            
            <p className="mb-3 font-bold">11. Contact Us</p>

            <p className="mb-3">If you have any questions or suggestions regarding our privacy policy, please contact us at:
            https://www.markdowntoday.com/contactus/.</p>
            <p className="mb-3">You may also mail us your questions or suggestions to us at the following address:</p>
            <p className="mb-2">Markdowntoday.com</p>
            <p className="mb-2">C/O Carociba Inc.</p>
            <p className="mb-2">442 5th Avenue # 1143</p>
            <p className="mb-2">NY10043</p>
            <p className="mb-2">USA</p>

        </div>
    );
}

export default PrivacyPolicy;