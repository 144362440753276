import React, { useState, useEffect } from "react";
import axios from "axios";
import dummyImage from "../../../Assets/profile.png";
import { BASE_URL, IMG_BASE_URL } from "../../../api/api";

const Affiliation = () => {
  const [affiliations, setAffiliations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    const fetchAffiliations = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.get(`${BASE_URL}/affiliation`, config);
        setAffiliations(response.data);
      } catch (error) {
        // Handle the error
        console.error("Error fetching affiliations:", error);
      }
    };

    fetchAffiliations();
  }, []); // Empty dependency array ensures the effect runs only once

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAffiliations = affiliations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(affiliations.length / itemsPerPage);

  console.log("Affiliations:", affiliations);

  return (
    <div className="min-h-screen p-4">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">Affiliation</h1>
        {currentAffiliations.length === 0 ? (
          <p>There is no data found.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
            {currentAffiliations.map((affiliation) => (
              <div
                key={affiliation.id}
                className="bg-base-200 rounded-lg shadow-lg p-4 flex flex-col items-center justify-center"
              >
                <div className="mr-4">
                  <img
                    src={`${IMG_BASE_URL}/users/${affiliation.userphoto}`}
                    alt={affiliation.name}
                    onError={(event) => (event.target.src = dummyImage)}
                    className="w-16 h-16 object-cover rounded-full"
                  />
                </div>
                <div className="flex flex-col items-center mt-3">
                  <h2 className="text-lg font-bold mb-1">{affiliation.name}</h2>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-center mt-4">
          <nav>
            <ul className="flex items-center">
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index}
                  className={`cursor-pointer px-3 py-1 rounded-lg mx-1 ${
                    index + 1 === currentPage
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Affiliation;
