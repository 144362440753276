import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../api/api";
import { useLocation } from "react-router-dom";

const EditProfile = () => {
  const [person, setPerson] = useState({
    name: "",
    email: "",
    address: "",
    photo: null,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();

  // Function to fetch the user's profile data
  const fetchProfileData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.get(`${BASE_URL}/profile`, config);

      // Populate the person state with the retrieved data
      setPerson(response.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  // Fetch profile data when the component mounts
  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "photo") {
      setPerson({
        ...person,
        [name]: files[0],
      });
    } else {
      setPerson({
        ...person,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const accessToken = localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const formData = new FormData();

      if (person.photo) {
        const allowedFileTypes = [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/svg+xml",
        ];
        const fileType = person.photo.type;
        if (!allowedFileTypes.includes(fileType)) {
          setErrorMessage(
            "Invalid file type. Please select a valid image file (jpeg, jpg, png, svg)."
          );
          return;
        }
      }

      for (const key in person) {
        formData.append(key, person[key]);
      }

      const response = await axios.post(
        `${BASE_URL}/profile`,
        formData,
        config
      );
      setSuccessMessage("Profile updated successfully.");
      setErrorMessage(""); // Clear any previous error message
      window.location.reload();
      console.log("Profile updated:", response.data);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("Error updating profile. Please try again.");
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="w-full md:w-full lg:w-full xl:w-full max-w-3xl">
        <h2 className="text-2xl font-bold mb-4">Edit Profile</h2>
        {successMessage && (
          <div className="bg-green-200 text-green-800 p-2 mb-4 rounded">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="bg-red-200 text-red-800 p-2 mb-4 rounded">
            {errorMessage}
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          className="bg-base-200 shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="name"
              name="name"
              value={person.name} // Populate with person's name
              onChange={handleChange}
              placeholder="Enter your name"
            />
          </div>
          {/* Add the profile picture field */}
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="photo">
              Profile Picture
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="file"
              id="photo"
              name="photo"
              accept="image/jpeg, image/jpg, image/png, image/svg+xml"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="email">
              Email (Can't be changed)
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              id="email"
              name="email"
              value={person.email} // Populate with person's email
              readOnly
              onChange={handleChange}
              placeholder="Enter your email"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="address">
              Address
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="address"
              name="address"
              value={person.address} // Populate with person's address
              onChange={handleChange}
              placeholder="Enter your address"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-[#ED3A58] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
