import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Pages/Shared/Navbar/Navbar";
import { Link, Outlet } from "react-router-dom";
import ProfileImage from "../Assets/profile-image.jpg";
import {
  Home,
  ReceiptTax,
  User,
  Clock,
  Pencil,
  UserGroup,
  Cog,
  Key,
  Logout,
  ViewList,
} from "heroicons-react";
import { AuthContext } from "../contexts/AuthProvider";
import axios from "axios";
import { BASE_URL, IMG_BASE_URL } from "../api/api";
import dummyImage from "../../src/Assets/profile.png";

const DashboardLayout = () => {
  const [person, setPerson] = useState([]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.get(`${BASE_URL}/profile`, config);
        setPerson(response.data);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    fetchStores();
  }, []);

  const { user, logOut } = useContext(AuthContext);

  const handleLogOut = async () => {
    try {
      await logOut();
    } catch (err) {
      console.log(err);
    }
  };

  const Menu = () => (
    <>
      <div className="my-4 flex flex-col items-center">
        <img
          className="rounded-full w-24 h-24 mb-2 object-cover"
          alt="Profile"
          src={person.photo ? `${IMG_BASE_URL}/users/${person.photo}` : (user.photoURL || dummyImage)}
        />
        <div className="text-lg font-semibold">{person?.name}</div>
      </div>
      <li>
        <Link to="/dashboard">
          <span className="mr-2">
            <Home className="w-5 h-5" />
          </span>
          Dashboard
        </Link>
      </li>
      <li>
        <Link to="/dashboard/affiliation">
          <span className="mr-2">
            <User className="w-5 h-5" />
          </span>
          Affiliation
        </Link>
      </li>
      <li>
        <Link to="/dashboard/withdraw">
          <span className="mr-2">
            <Clock className="w-5 h-5" />
          </span>
          Withdraw
        </Link>
      </li>
      <li>
        <Link to="/dashboard/tickets">
          <span className="mr-2">
            <Pencil className="w-5 h-5" />
          </span>
          Tickets
        </Link>
      </li>
      {/* <li>
        <Link to="/dashboard/disputes">
          <span className="mr-2">
            <UserGroup className="w-5 h-5" />
          </span>
          Disputes
        </Link>
      </li> */}
      <li>
        <Link to="/dashboard/edit-profile">
          <span className="mr-2">
            <Cog className="w-5 h-5" />
          </span>
          Edit Profile
        </Link>
      </li>
      <li>
        <Link to="/dashboard/reset-password">
          <span className="mr-2">
            <Key className="w-5 h-5" />
          </span>
          Reset Password
        </Link>
      </li>
      <li>
        <Link to="/">
          <span className="mr-2">
            <ReceiptTax className="w-5 h-5" />
          </span>
          Go Back To Home
        </Link>
      </li>
      <li>
        <Link onClick={handleLogOut}>
          <span className="mr-2">
            <Logout className="w-5 h-5" />
          </span>
          Logout
        </Link>
      </li>
    </>
  );

  const [userFromDB, setUserFromDB] = useState([]);

  useEffect(() => {
    const fetchAffiliations = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.get(`${BASE_URL}/profile`, config);
        setUserFromDB(response.data);
      } catch (error) {
        // Handle the error
        console.error("Error fetching affiliations:", error);
      }
    };

    fetchAffiliations();
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div>
      <Navbar></Navbar>
      <div className="drawer drawer-mobile lg:drawer-open">
        <input
          id="dashboard-drawer"
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content items-center">
          <Outlet></Outlet>
        </div>
        <div className="drawer-side">
          <label htmlFor="dashboard-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 h-full bg-base-200 text-base-content">
            <Menu />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
