import React from "react";
import { Link } from "react-router-dom";

const Apply = () => {
  document.title = "Apply Now";
  return (
    <div className="mx-auto">
      <div className="hero bg-[#ebaeae] py-10 justify-start md:pl-20">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <div>
            <h1 className="text-3xl font-bold">Carrer at Markdowntoday</h1>
            <p className="py-6">
              Current job opportunities are posted here as they become
              available.
            </p>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto md:px-24 py-5 md:py-16">
        <table className="table table-zebra">
          {/* head */}
          <thead>
            <tr className="text-xl">
              <th>#SL</th>
              <th>Possition Title</th>
              <th>State</th>
              <th>Country</th>
              <th>Office Location</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td>Sr. Manager, Survey Data Visualization</td>
              <td></td>
              <td>United States</td>
              <td>Remote</td>
              <td>
                <Link
                  to="mailto:jobs@markdowntoday.com"
                  className="md:p-2 text-[12px] bg-[#ED3A58] text-white rounded-sm"
                >
                  Apply Now
                </Link>
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td>Sr. Analyst, Market Research</td>
              <td>MN</td>
              <td>United States</td>
              <td>Minnesota</td>
              <td>
                <Link
                  to="mailto:jobs@markdowntoday.com"
                  className="md:p-2 text-[12px] bg-[#ED3A58] text-white rounded-sm"
                >
                  Apply Now
                </Link>
              </td>
            </tr>
            <tr>
              <th>3</th>
              <td>Sr. Account Manager</td>
              <td></td>
              <td>United States</td>
              <td>Remote</td>
              <td>
                <Link
                  to="mailto:jobs@markdowntoday.com"
                  className="md:p-2 text-[12px] bg-[#ED3A58] text-white rounded-sm"
                >
                  Apply Now
                </Link>
              </td>
            </tr>
            <tr>
              <th>4</th>
              <td>Sr. Director, Sales - Performance Marketing</td>
              <td></td>
              <td>United States</td>
              <td>Remote</td>
              <td>
                <Link
                  to="mailto:jobs@markdowntoday.com"
                  className="md:p-2 text-[12px] bg-[#ED3A58] text-white rounded-sm"
                >
                  Apply Now
                </Link>
              </td>
            </tr>
            <tr>
              <th>5</th>
              <td>Director, Sales - Shopper Marketing</td>
              <td></td>
              <td>United States</td>
              <td>Remote</td>
              <td>
                <Link
                  to="mailto:jobs@markdowntoday.com"
                  className="md:p-2 text-[12px] bg-[#ED3A58] text-white rounded-sm"
                >
                  Apply Now
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-4 p-5 md:pl-24 mb-10 font-bold">
        Note: If you have any query Please mail to jobs@markdowntoday.com
      </div>
    </div>
  );
};

export default Apply;
