import React from "react";


const ReferralPolicy = () => {
    document.title = "Referral Policy";
    return (
        <div className="px-40 py-10">
            <div className="text-center">
                <h1 className="font-bold text-2xl mb-10">
                    REFERRAL POLICY
                    <hr/>
                </h1>
            </div>
            <p className="mb-3">Markdowntoday.com Refer-a-Friend bonus program allows for members to receive a monetary reward for participating in the program. Markdowntoday.com members will earn a $30 Refer-a-Friend bonus for each qualified new member they refer to. Existing Markdowntoday.com members are not eligible as new referrals.</p>
            <p className="mb-3">To qualify for a $30, Refer-a-Friend bonus, new referrals must click on your unique Refer-a-Friend link, join Markdowntoday.com and earn $10 or more in Cash Back (not including bonuses and earnings from non-purchase activity) within 365 days of account creation. Each new member referred through the Refer-a-Friend program will be eligible for a $10 Sign-Up bonus. To qualify for a $10 Sign-Up bonus, please refer to the <span className="text-red-600">Markdowntoday.com Terms &amp; Conditions</span>.</p>
            <p className="mb-3">Additionally, existing Markdowntoday.com members may qualify for a $30 Refer-a-Friend bonus for referring new members to Markdowntoday.com Free Product Offers. To receive the bonus, a referred new member needs to complete the offer. For more information on Free Product Offers see here. New members who complete a Free Product Offer are not eligible to receive a Sign-Up Bonus. Accounts will be terminated and bonuses forfeited for improper use of the Refer-a-Friend program, including, but not limited to: using search engine sponsored listings or any other paid advertising; creating multiple accounts for one individual; posting your referral link on merchant&#39;s site, merchant&#39;s social media page or other similar activity; impersonating Markdowntoday.com; spamming web forums and other online properties. Determination of bonus eligibility will be at the sole discretion of Markdowntoday.com. The Refer-a-Friend program may be altered and/or discontinued at any time. Terms subject to change. See the <span className="text-red-600">Markdowntoday.com Terms &amp; Conditions</span> for more information.</p>
        </div>
    )
}


export default ReferralPolicy;