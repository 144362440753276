// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDPS3xOPAt5YQ5pyvOzpIQCyQef8OkEKK4",
  authDomain: "markdowntodayapp.firebaseapp.com",
  projectId: "markdowntodayapp",
  storageBucket: "markdowntodayapp.appspot.com",
  messagingSenderId: "812870019976",
  appId: "1:812870019976:web:427cde77cc69c941c9a83d"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;