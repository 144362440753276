import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import headerPhoto from "../../../Assets/headerPhoto.jpg";
import headerPhoto2 from "../../../Assets/headerPhoto2.jpg";
import headerPhoto3 from "../../../Assets/headerPhoto3.jpg";
import headerPhoto4 from "../../../Assets/headerPhoto4.jpg";
import headerPhoto6 from "../../../Assets/headerPhoto6.jpg";
import { useState } from "react";
import "../HomeCSS/Home.css";
import { Link } from "react-router-dom";

const HeroBanner = () => {
  const slides = [
    {
      image: headerPhoto4,
      headline: "We Are Live Now!",
      title: "Join Us and Take Your $10 Bonus",
      buttonText: "Get Help",
      buttonLink: "/help",
      buttonText2: "Coupons",
      buttonLink2: "/coupons",
    },
    {
      image: headerPhoto6,
      headline: "Join Us on our exciting journey!",
      title:
        "Began A New Era Be A Part of This",
      buttonText: "Blog",
      buttonLink: "/blog",
      buttonText2: "What's New",
      buttonLink2: "/whatsnew",
    },
    // {
    //   image: headerPhoto3,
    //   headline: "Shop, Earn, and Reap the Benefits!",
    //   title:
    //     "Shop Smarter, Earn Better: Get paid while you explore our curated selection!",
    //   buttonText: "About",
    //   buttonLink: "/about",
    //   buttonText2: "Press Kit",
    //   buttonLink2: "/press",
    // },
    // Add more slides as needed
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative">
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        onChange={handleSlideChange}
      >
        {slides.map((slide, index) => (
          <div key={index} className="relative">
            <img src={slide.image} alt={`Slide ${index + 1}`} />
            <div className="absolute inset-0 bg-gradient-to-r from-base-300 to-transparent"></div>
          </div>
        ))}
      </Carousel>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-start">
        <div className="md:max-w-2xl max-w-xs mx-auto p-4 ml-4 md:px-20">
          <h2 className="GradiantTextColour md:text-7xl text-2xl mb-4">
            {slides[currentSlide].headline}
          </h2>
          <h1
            className=" font-medium md:text-xl text-base-content
           mb-6"
          >
            {slides[currentSlide].title}
          </h1>
          <div className="space-x-4">
            <Link
              to={slides[currentSlide].buttonLink}
              className="bg-gradient-to-r from-orange-500 to-orange-600 hover:bg-gradient-to-r hover:from-orange-600 hover:to-orange-700 text-white px-6 py-3 rounded-md transition-colors duration-300 font-medium"
            >
              {slides[currentSlide].buttonText}
            </Link>
            <Link
              to={slides[currentSlide].buttonLink2}
              className="bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-r hover:from-blue-600 hover:to-blue-700 text-white px-6 py-3 rounded-md transition-colors duration-300"
            >
              {slides[currentSlide].buttonText2}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
