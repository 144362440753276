import React, { useContext, useEffect, useState } from "react";
import { BASE_URL, IMG_BASE_URL } from "../../../api/api";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthProvider";

const BlogPreview = () => {
  const [bannerData, setBannerData] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/top/small/banner`);
        const data = await response.json();
        setBannerData(data);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchData();
  }, []);

  // Filter and modify the bannerData array
  const filteredBanners = bannerData.slice(-3).map((banner, index) => {
    if (index === 0) {
      return {
        ...banner,
        buttonText: "Signup",
        buttonLink: "/signup",
      };
    } else {
      return {
        ...banner,
        buttonText: "Signin",
        buttonLink: "/login",
      };
    }
  });

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-6 justify-center px-4 md:px-6 lg:px-10 my-9">
      {filteredBanners.map((banner, index) => (
        <div key={index} className="flex flex-col items-start bg-base-200 rounded-lg shadow-md p-4">
          <img
            src={`${IMG_BASE_URL}/banners/${banner.photo}`}
            alt={banner.title}
            className="w-full h-auto mb-2 rounded-md"
          />
          <h2 className="text-2xl max-w-[250px] font-semibold text-blue-600">{banner.title}</h2>
          <p className="mt-2 max-w-[300px] text-base-900">{banner.description}</p>
          <div className="flex items-center mt-4">
            {user?.email ? (
              <span className="bg-[#E94036] rounded-md px-3 py-2 text-sm text-white mr-2">
                <Link to="/dashboard/affiliation">Share With Friends</Link>
              </span>
            ) : (
              <span className="bg-blue-600 rounded-md px-3 py-2 text-sm text-white mr-2">
                <Link to={banner.buttonLink}>{banner.buttonText}</Link>
              </span>
            )}
            <span className="bg-sky-400 rounded-md px-3 py-2 text-sm text-white">
              <Link to="/help">Need Help?</Link>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPreview;
