import React, { createContext, useContext, useEffect, useState } from 'react';

const LocationContext = createContext(null);

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState({ city: '', country: '' });

  useEffect(() => {
    const city = localStorage.getItem('city');
    const country = localStorage.getItem('country');
    setLocation({ city, country });
  }, []);

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => useContext(LocationContext);
