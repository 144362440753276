import React from 'react';

const NoCouponsMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-80">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-16 h-16 text-red-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 0a10 10 0 110 20 10 10 0 010-20zm0 2a8 8 0 100 16 8 8 0 000-16zm-1 5a1 1 0 112 0v5a1 1 0 01-2 0V7z"
          clipRule="evenodd"
        />
      </svg>
      <p className="mt-4 text-lg text-gray-600">No coupons available</p>
    </div>
  );
};

export default NoCouponsMessage;
