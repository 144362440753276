import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL, IMG_BASE_URL } from '../../api/api';
import { AuthContext } from '../../contexts/AuthProvider';
import axios from 'axios';

const CashBackLandingForDeals = () => {
  const { id } = useParams();
  const [partner, setPartner] = useState({});
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetch(`${BASE_URL}/single-deal/${id}`)
      .then((response) => response.json())
      .then((data) => setPartner(data))
      .catch((error) => console.log(error));
  }, []);

  console.log("Check it:", partner);

  useEffect(() => {
    if (user.email && partner.amount && partner.vendor_name && partner.affiliate_link) {
      handleClick();
    }
  }, [user.email, partner.amount, partner.vendor_name, partner.affiliate_link]);

  const handleClick = () => {
    const formData = new FormData();
    formData.append('email', user.email);
    formData.append('amount', partner.amount);
    formData.append('vendor', partner.vendor_name);
    formData.append('aff_link', partner.affiliate_link);

    const accessToken = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .post(`${BASE_URL}/collect-user-data`, formData, config)
      .then((response) => {
        // Handle the response if needed
        console.log(response.data);
      })
      .catch((error) => {
        // Handle the error if needed
        console.log(error);
      });
  };

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      window.location.href = partner.affiliate_link;
    }, 5000);

    return () => clearTimeout(redirectTimer);
  }, [partner.affiliate_link]);

  return (
    <div className="bg-base-200 rounded-lg shadow-md p-6 md:mx-52 md:my-10">
      <div className="flex justify-center">
        <img src={`${IMG_BASE_URL}/partner/${partner.photo}`} alt="Logo" className="h-20 w-auto" />
      </div>
      <div className="mt-4 text-center">
        <p className="text-gray-600">Complete your purchase to earn</p>
        <p className="text-2xl font-bold">{partner.name}</p>
      </div>
      <div className="mt-6 text-center">
        <p>
          You will be automatically redirected to {partner.name} in a few moments. If you do not wish to wait, you may{' '}
          <a href={partner.link} className="text-blue-500 underline">
            click here to go there now
          </a>
        </p>
        <span className="loading loading-dots loading-lg"></span>
      </div>
      <div className="mt-6 text-center">
        <p className="">Confirmation Details: Click ID: {partner.id}, Email: {user.email}</p>
        <p className="">Cash Back will be added to your Markdowntoday account</p>
      </div>
    </div>
  );
};

export default CashBackLandingForDeals;
