import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IMG_BASE_URL } from "../../../api/api";
import { Link } from "react-router-dom";

const HotDealsCard = ({ product }) => {
  return (
    <>
      <Link to={`/top-deals/${product.id}`}>
        <div className="w-full p-1 hover:scale-105 transition-transform duration-300 cursor-pointer">
          <div>
            <img
              src={`${IMG_BASE_URL}/products/${product.photo}`}
              alt={product.headline}
              className="w-full h-full object-cover rounded-md"
            />
            <div className="p-2">
              <h2 className="text-xs md:text-xl font-bold">
                {product.name.length > 20
                  ? `${product.name.substring(0, 20)}...`
                  : product.name}
              </h2>
              <p>{product.category}</p>
              <div className="flex justify-start gap-3 items-center mt-1">
                {/* <p className="text-sm md:text-lg line-through">
                {product.price}
              </p> */}
                <p className="text-xs md:text-lg font-bold">${product.price}</p>
                <p className="text-xs md:text-lg">
                  {product.amount}% Cash Back
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default HotDealsCard;
