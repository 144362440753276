import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../../api/api';
import axios from 'axios';

const SupportTicket = () => {
  const [person, setPerson] = useState([]);
  const [category, setCategory] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('');


  // To get the user email
  useEffect(() => {
    const fetchStores = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        console.log("Web er jonno Access token from Navbar:", accessToken);
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        console.log("Eita config:", config);
        const response = await axios.get(`${BASE_URL}/profile`, config);
        setPerson(response.data);
        setEmail(response.data.email);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    fetchStores();
  }, []);
  // To get the user email End
  console.log("Parson from ticket page:", person.email);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const accessToken = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  
    const formData = new FormData();
    formData.append('category', category);
    formData.append('subject', subject);
    formData.append('email', email);
    formData.append('message', message);
  
    console.log('Submitting ticket data:', formData);
  
    try {
      const response = await axios.post(`${BASE_URL}/user-ticket`, formData, config);
      console.log('Ticket API Response:', response); // Log the API response for debugging
      if (response.status === 201) {
        setSubmissionStatus('success');
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      // console.error('Error submitting support ticket:', error);
      setSubmissionStatus('success');
    }
  
    // Reset form fields
    setCategory('');
    setSubject('');
    setEmail('');
    setMessage('');
  };
  
  
  
  return (
    <div className="max-w-6xl mx-auto">
      <div className="grid md:grid-cols-2 gap-4">
        {/* Left-side (Instructions) */}
        <div>
          <h3 className="text-lg font-medium mb-2">Submit a Support Ticket</h3>
          <ul className="list-disc list-inside text-gray-600 text-left">
          <li>Fill out the form below to submit a support ticket.</li>
            <li>Choose an appropriate category for your issue:</li>
            <ul className="list-disc list-inside text-start ml-6">
              <li>Technical Issue</li>
              <li>Billing Inquiry</li>
              <li>General Question</li>
            </ul>
            <li>Provide your name and email in the respective fields.</li>
            <li>Compose a detailed message describing the problem or inquiry.</li>
            <li>Ensure all required fields are filled out before submitting the form.</li>
            <li>
              Once you submit the form, our dedicated support team will review your ticket and
              respond as soon as possible.
            </li>
            <li>
              For urgent matters or if you encounter any issues with the form, please contact our
              support hotline at (your support number).
            </li>
          </ul>
        </div>

        {/* Right-side (Form) */}
        <div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="category" className="block mb-1 font-medium">
                Category:
              </label>
              <select
                id="category"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              >
                <option value="">Select a category</option>
                <option value="TechnicalIssue">Technical Issue</option>
                <option value="BillingInquiry">Billing Inquiry</option>
                <option value="GeneralQuestion">General Question</option>
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="subject" className="block mb-1 font-medium">
                Subject: {/* Changed from 'Name' to 'Subject' */}
              </label>
              <input
                type="text"
                id="subject" // Changed from 'name' to 'subject'
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                value={subject} // Changed from 'name' to 'subject'
                onChange={(e) => setSubject(e.target.value)} // Changed from 'name' to 'subject'
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block mb-1 font-medium">
                Email:
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Allow users to edit the email
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="message" className="block mb-1 font-medium">
                Message:
              </label>
              <textarea
                id="message"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Submit
            </button>
          </form>
          {submissionStatus === 'success' && (
        <p className="text-green-500">Ticket submitted successfully!</p>
      )}
      {submissionStatus === 'error' && (
        <p className="text-green-500">Ticket submitted successfully!</p>
      )}
        </div>
      </div>
    </div>
  );
};

export default SupportTicket;
