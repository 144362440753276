import React from 'react';

const HighestCashBackGuarantee = () => {
  return (
    <div className="bg-base-200 rounded shadow p-4">
      <h2 className="text-2xl font-bold mb-4">Highest Cash Back Guarantee</h2>

      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">What is the Highest Cash Back Guarantee?</h3>
        <p className="text-gray-600">
          Our Highest Cash Back Guarantee ensures that you receive the highest cash back rates among all cash back websites. Here's how it works:
        </p>
        <ul className="list-disc ml-6 mt-2 text-left">
          <li>If you find a higher cash back rate for the same store on another cash back website, let us know.</li>
          <li>We'll verify the offer and beat the higher rate, providing you with the best cash back deal available.</li>
        </ul>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">How to Claim the Highest Cash Back Guarantee?</h3>
        <p className="text-gray-600">
          Follow these steps to claim the Highest Cash Back Guarantee:
        </p>
        <ol className="list-decimal ml-6 mt-2 text-left">
          <li>Find a higher cash back rate for the same store on another cash back website.</li>
          <li>Take a screenshot or provide a link to the offer on the other website.</li>
          <li>Contact our customer support team and share the details of the offer.</li>
          <li>Our team will review the information and verify the offer.</li>
          <li>If the offer is valid, we'll beat the higher rate and update your cash back accordingly.</li>
        </ol>
      </div>

      <div>
        <h3 className="text-lg font-bold mb-2">Terms and Conditions</h3>
        <p className="text-gray-600">
          Please note the following terms and conditions for the Highest Cash Back Guarantee:
        </p>
        <ul className="list-disc ml-6 mt-2 text-left">
          <li>The offer must be for the same store and the same product or service.</li>
          <li>Offers from other cash back websites must be publicly available and verifiable.</li>
          <li>We reserve the right to determine the validity of the offer and the eligibility for the guarantee.</li>
          <li>The guarantee applies to cash back rates and does not include additional promotions or discounts.</li>
          {/* Add more terms and conditions if applicable */}
        </ul>
      </div>
    </div>
  );
};

export default HighestCashBackGuarantee;
