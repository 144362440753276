import React from "react";

import aboutImage from "../../Assets/about/about-img.jpg";
import tayoImage from "../../Assets/about/tayo.png";
import olaImage from "../../Assets/about/ola.png";
import deniseImage from "../../Assets/about/denise.png";
import sumonImage from "../../Assets/about/sumon.png";
import getinTouch from "../../Assets/about/bg-01.jpg";
import aboutImage1 from "../../Assets/about/bg-about.jpg";
import { Link } from "react-router-dom";

const About = () => {
  document.title = "About Us";
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Function to scroll to the top of the page
  };


  return (
    <div className="mx-auto">
      <div
        className="hero text-left min-h-[30vh]"
        style={{
          backgroundImage: `url(${aboutImage1})`,
          height: "auto",
          width: "100%",
          backgroundPosition: "center center",
          background: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="text-left">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl text-white text-left font-bold">About Us</h1>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 px-8 md:px-24 my-20">
        <div>
          <h3 className="text-4xl font-bold my-10">Who We Are?</h3>
          <p className="text-lg justify-between text-[#636262]">
            Markdowntoday is a limited liability company that is incorporated in
            USA. As its basic business model, Markdowntoday offers renewed
            shopping experience, tools, and suggestions that help shoppers win
            and save big. Apart from its easily navigable web platform,
            Markdowntoday helps shoppers discover, shop and save on the best of
            the best deals anytime, anywhere using mobile apps.
          </p>
        </div>
        <div>
          <img src={aboutImage} className="rounded-md" alt="About" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 px-8 md:px-24 pb-20 bg-slate-50">
        <div>
          <h3 className="text-4xl font-bold my-10">Company Overview</h3>
          <p className="text-lg justify-between text-[#636262] mb-5">
            In its early years, Markdowntoday~~ established itself as an
            eCommerce platform. Over the years, and with our cumulative
            experience we felt Markdowntoday~~ could become something more than
            a marketplace. Having an effective affiliation with thousands of
            stores and major brands in the global market, Markdowntoday~~ could
            create a win-win environment for both retailers and shoppers.
          </p>

          <p className="text-lg justify-between text-[#636262]">
            Markdowntoday~~ introduces this large segment of consumers to
            industries such insurance, air travel, etc with over thousands of
            brands, stores, and services. It helps shoppers to unlock savings
            hacks, on the go product reviews, and more to help them make better
            purchase decisions. Apart from its easily navigable web platform,
            Markdowntoday~~ helps shoppers discover, shop and save on the best
            of the best deals anytime, anywhere using mobile apps.
          </p>
        </div>
        <div>
          <h3 className="text-4xl font-bold my-10">Vision and Value</h3>
          <p className="text-lg justify-between text-[#636262]">
            Markdowntoday~~ is more than just an eCommerce company. It’s a
            member of society that wishes retailers to offer better deals, and
            through that, help consumers better their quality of life.
          </p>
          <h3 className="text-4xl font-bold my-10">Mission</h3>
          <p className="text-lg justify-between text-[#636262]">
            To empower every consumer and brand globally to achieve more with
            less.
          </p>
        </div>
      </div>

      <div
        className="h-auto py-28"
        style={{
          backgroundImage: `url(${getinTouch})`,
          height: "auto",
          width: "100%",
          backgroundPosition: "center center",
          background: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="justify-items-center text-center">
          <h3 className="">HAVE A QUESTION?</h3>
          <h2 className="text-6xl font-bold py-10">Get in Touch</h2>
          <p className="px-8 md:px-80 mb-10">
            Please feel free to reach out if you have any questions about our
            products and services. We are happy to answer your question the
            quickest possible time.
          </p>
          <Link to="/contact" onClick={scrollToTop} className="bg-red-500 hover:bg-orange-600 text-white btn rounded-lg">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
