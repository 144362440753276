import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IMG_BASE_URL } from "../../../api/api";
import TestImg from "../../../Assets/Nike.png";
import { Link } from "react-router-dom";

const SearchBar = () => {
  const searchWords = [
    "Fiverr",
    "Outgrow",
    "Systeme",
    "Typeform",
    "Spoket",
    "GreenGeeks",
  ];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [animatedWord, setAnimatedWord] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [isWordComplete, setIsWordComplete] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTyping && !isWordComplete) {
        setAnimatedWord(
          searchWords[currentWordIndex].slice(0, animatedWord.length + 1)
        );
        if (animatedWord === searchWords[currentWordIndex]) {
          setIsWordComplete(true);
          setTimeout(() => {
            setIsWordComplete(false);
            setIsTyping(false);
          }, 1000); // Pause for 1 second after a word is fully typed
        }
      } else if (!isTyping && !isWordComplete) {
        setAnimatedWord(animatedWord.slice(0, animatedWord.length - 1));
        if (animatedWord === "") {
          setIsWordComplete(true);
          setTimeout(() => {
            setIsWordComplete(false);
            setIsTyping(true);
            setCurrentWordIndex((currentWordIndex + 1) % searchWords.length);
          }, 500); // Pause for 0.5 seconds after a word is fully deleted
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, [currentWordIndex, animatedWord, isTyping, isWordComplete]);

  // console.log("Search Data:", filteredSuggestions.products);

  // Ref for the dropdown list element
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Close the dropdown when clicking outside
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilteredSuggestions([]); // Hide the dropdown when clicking outside
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Update the filtered suggestions whenever the user types something
  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setFilteredSuggestions([]); // Clear the suggestions when the input is empty
    } else {
      try {
        const response = await axios.get(
          `https://ap.markdowntoday.com/api/search?name=${inputValue}`
        );
        setFilteredSuggestions(response.data);
      } catch (error) {
        console.error("Error fetching data from the API:", error);
      }
    }
    setSelectedSuggestion(null);
  };

  // Update the selected suggestion when the user clicks on a suggestion
  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
  };

  return (
    <div className="flex items-center justify-center mx-4 z-50 md:w-[462px]">
      <div className="w-full max-w-lg rounded-md shadow-lg">
        <div className="relative" ref={dropdownRef}>
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              className="w-6 h-6 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-4.35-4.35"
              />
              <circle cx="11" cy="11" r="8" />
            </svg>
          </span>
          <input
            type="text"
            className="w-full py-2 pl-10 pr-3 outline-none focus:ring focus:border-primary-400 rounded-md placeholder-gray-500"
            placeholder={`Search "${animatedWord}"`}
            onChange={handleInputChange}
          />
        </div>
        {filteredSuggestions?.partners?.length > 0 && (
          <ul className="bg-white bg-opacity-90 border border-gray-300 mt-2 rounded-lg absolute w-full max-h-36 overflow-y-auto">
            {filteredSuggestions?.partners.map((suggestion, index) => (
              <li
                key={suggestion.id}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                  index !== filteredSuggestions.partners.length - 1
                    ? "border-b border-gray-300"
                    : ""
                }`}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <Link
                  to={`/cash-back-landing/${suggestion?.name}`}
                  className="flex items-center"
                >
                  <img
                    className="w-8 h-8 mr-2 rounded-lg"
                    src={`${IMG_BASE_URL}/partner/${suggestion?.photo}`}
                    alt={suggestion?.name}
                  />
                  <div>
                    <p className="font-semibold">{suggestion?.name}</p>
                    <p className="text-gray-600">{suggestion?.description}</p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
        {filteredSuggestions?.coupons?.length > 0 && (
          <ul className="bg-white bg-opacity-90 border border-gray-300 mt-2 rounded-lg absolute w-full max-h-36 overflow-y-auto">
            {filteredSuggestions?.coupons.map((suggestion, index) => (
              <li
                key={suggestion.id}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                  index !== filteredSuggestions.coupons.length - 1
                    ? "border-b border-gray-300"
                    : ""
                }`}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <Link
                  to={`/cash-back-landing/${suggestion?.coupon_title}`}
                  className="flex items-center"
                >
                  <img
                    className="w-8 h-8 mr-2 rounded-lg"
                    src={suggestion?.image_url}
                    alt={suggestion?.coupon_title}
                  />
                  <div>
                    <p className="font-semibold">{suggestion?.coupon_title}</p>
                    <p className="text-gray-600">{suggestion?.description}</p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
        {filteredSuggestions?.products?.length > 0 && (
          <ul className="bg-white bg-opacity-90 border border-gray-300 mt-2 rounded-lg absolute w-full max-h-36 overflow-y-auto">
            {filteredSuggestions?.products.map((suggestion, index) => (
              <li
                key={suggestion.id}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                  index !== filteredSuggestions.products.length - 1
                    ? "border-b border-gray-300"
                    : ""
                }`}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <Link
                  to={`/top-deals/${suggestion.id}`}
                  className="flex items-center"
                >
                  <img
                    className="w-8 h-8 mr-2 rounded-lg"
                    src={`${IMG_BASE_URL}/thumbnails/${suggestion?.thumbnail}`}
                    alt={suggestion?.name}
                  />
                  <div className="text-left">
                    <p className="font-semibold">
                      {suggestion.name.length > 20
                        ? `${suggestion.name.substring(0, 20)}...`
                        : suggestion.name}
                    </p>
                    <p className="text-gray-600">Price: {suggestion?.price}</p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Display selected suggestion details */}
      {/* {selectedSuggestion && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">{selectedSuggestion.name}</h2>
          <p className="text-gray-600">{selectedSuggestion.description}</p>
          <img
            className="w-32 h-32 mt-2 rounded-lg"
            src={TestImg}
            alt={selectedSuggestion.name}
          />
        </div>
      )} */}
    </div>
  );
};

export default SearchBar;
