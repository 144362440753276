import React from "react";
import Coupon from "./Coupons";
import Sidebar from "./Sidebar";

const CouponPage = () => {
  return (
    <div className="pb-10">
      <div className="flex flex-wrap">
        <div className="w-full md:w-9/12 p-1">
          {/* Left section content */}
          <Coupon></Coupon>
        </div>
        <div className="w-full md:w-3/12 pr-10">
          {/* Right section content */}
          <Sidebar></Sidebar>
          {/* <RecommendedStores></RecommendedStores> */}
        </div>
      </div>
    </div>
  );
};

export default CouponPage;
