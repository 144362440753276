import React, { useEffect } from "react";
import Banner from "../Banner/Banner";
import TopDeals from "../TopDeals/TopDeals";
import BlogPreview from "../BlogPreview/BlogPreview";
import Hero from "../Hero/Hero";
import CheckBackOffer from "../CashBackOffer/CashBackOffer";
import CouponPage from "../../Coupons/CouponPage";
import BlogPreview3 from "../BlogPreview/BlogPreview3";
import HotDeals from "../HotDeals/HotDeals";
import AppDownload from "../AppDownload/AppDownload";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = "Markdowntoday | Amazing App for Shopping Deals and Coupon"; // Set your desired title here
    document.title = pageTitle;
  }, [location]);
  return (
    <div>
    {/* <CookiePermission /> */}
      {/* <Banner></Banner> */}
      <Hero></Hero>
      <BlogPreview></BlogPreview>
      <TopDeals></TopDeals>
      {/* <BlogPreview2></BlogPreview2> */}
      <AppDownload></AppDownload>
      <CheckBackOffer></CheckBackOffer>
      <BlogPreview3></BlogPreview3>
      <HotDeals></HotDeals>
      <h1 className="text-3xl font-bold text-left bg-gradient-to-r from-red-400 to-orange-500 text-transparent bg-clip-text px-10 mt-12">
        Get Your Coupons
        <hr className="mt-3" />
      </h1>
      
      <CouponPage></CouponPage>
    </div>
  );
};

export default Home;
