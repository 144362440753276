import React from "react";

const Sidebar = () => {
  return (
    <div className="hidden md:block max-w-sm mx-auto bg-white rounded-md overflow-hidden shadow-md mt-8">
      <img
        className="w-full h-full object-cover"
        src="./SidebarPost.png"
        alt="Card Image"
      />
      <div className="p-4 text-center">
        <h1 className="text-lg font-bold text-gray-600">Shop RV Share With</h1>
        <h2 className="text-lg font-semibold text-green-600">8% Cash Back</h2>
      </div>
    </div>
  );
};

export default Sidebar;
