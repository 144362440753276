import React from 'react';
import Signup from './Signup';
import { Link } from 'react-router-dom';

const UserSignup = () => {
  return (
    <div className="grid md:grid-cols-2 bg-base-100">
      {/* Left Side */}
      <div className="md:relative md:z-0 mt-20">
        <iframe
          title="YouTube video player"
          className="w-full md:h-full h-60 md:object-cover object-center md:absolute md:inset-0"
          src="https://www.youtube.com/embed/SLTbrPcwm6Q"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      {/* Right Side */}
      <div className="flex items-center justify-center">
        <div className="max-w-md w-full space-y-2">
          <div className="text-center">
            <h2 className="mt-6 text-3xl font-extrabold text-base-600">
              Create a new account
            </h2>
          </div>
          <div className="shadow-md rounded-md p-6">
            <Signup />
            <div className="mt-4 text-center">
              <span className="text-base text-gray-600">Already have an account? </span>
              <Link
                to="/login"
                className="ml-1 text-base text-blue-600 underline hover:text-blue-800"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignup;
