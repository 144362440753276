import React from 'react';
import MarkdowntodayLogo from '../../Assets/logo.svg';
import Login from './Login';
import { Link } from 'react-router-dom';

const UserLogin = () => {
  return (
    <div className="grid md:grid-cols-2  bg-base-100">
      {/* Left Side */}
      <div className="md:relative md:z-0 mt-20">
        <iframe
          title="YouTube video player"
          className="w-full md:h-full h-60 md:object-cover object-center md:absolute md:inset-0"
          src="https://www.youtube.com/embed/SLTbrPcwm6Q"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      {/* Right Side */}
      <div className="flex items-center justify-center md:h-full">
        <div className="max-w-md w-full space-y-2">
          <div className="text-center md:mt-0 mt-8">
            <img
              className="mx-auto h-12 w-auto"
              src={MarkdowntodayLogo}
              alt="Logo"
            />
            <h2 className="mt-6 text-3xl font-extrabold text-base-600">
              Sign in to your account
            </h2>
            <p className="mt-2 text-base text-base-400">
              Welcome back! Please login to continue.
            </p>
          </div>
          <div className="shadow-md rounded-md p-6">
            <Login />
            <div className="mt-4 text-center">
              <span className="text-base text-gray-600">
                Don't have an account?
              </span>
              <Link
                to="/signup"
                className="ml-1 text-base text-blue-600 underline hover:text-blue-800"
              >
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
