import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, IMG_BASE_URL } from '../../../../api/api';

const LastVisitedStores = () => {
  const [stores, setStores] = useState([]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.get(`${BASE_URL}/last-visited-store`, config);
        setStores(response.data);
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };

    fetchStores();
  }, []);

  console.log("Last visited Stores:", stores);

  return (
    <div className="container mx-auto mt-6">
      <h1 className="text-xl font-bold mb-4">Last Visited Stores</h1>
      {stores.length === 0 ? (
        <p>It appears that you haven't visited any stores yet!</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 max-w-5xl">
          {stores.slice(0, 4).map((store) => (
            <div key={store.id} className="bg-base-200 rounded shadow p-4">
              <img
                src={`${IMG_BASE_URL}/partner/${store.vendorphoto}`}
                alt={store.vendor}
                className="w-full h-40 object-cover mb-4 rounded"
              />
              <h2 className="text-lg font-bold mb-2">{store.vendor}</h2>
              <p className="text-gray-600">{store.address}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LastVisitedStores;
