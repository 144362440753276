import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL, IMG_BASE_URL } from '../../api/api';

const SingleBlogPost = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    fetch(`${BASE_URL}/blog/${slug}`)
      .then((response) => response.json())
      .then((data) => setBlog(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-6xl mx-auto bg-base-200 rounded-lg shadow-lg p-6">
        <img
          className="rounded-lg mb-4"
          src={`${IMG_BASE_URL}/blogs/${blog.photo}`}
          alt="Blog post"
        />
        <h2 className="text-3xl font-bold mb-2">{blog.title}</h2>
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: blog.details }}
        />
      </div>
    </div>
  );
};

export default SingleBlogPost;
