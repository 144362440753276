import React from "react";
import ref1 from "../../Assets/referral/referral1.jpg";
import ref2 from "../../Assets/referral/referral2.jpg";
import ref3 from "../../Assets/referral/referral3.jpg";
import ref4 from "../../Assets/referral/referral4.jpg";


const Referral = () => {
  document.title = "Referral Programm";
  return (
    <div className="mx-auto">
      <section
        className="bg-hero bg-cover bg-center h-screen relative"
        style={{
          backgroundImage: `url(${ref4})`,
        }}
      >
        <div
          className="container mx-auto h-full flex items-center justify-start"
          style={{
            position: "relative",
            zIndex: "1",
          }}
        >
          <div className="max-w-lg px-6">
            <h1 className="text-4xl md:text-6xl text-white font-bold mb-6">
              Refer Markdowntoday, Get Hard Cash.
            </h1>
            <p className="text-lg md:text-xl text-white mb-8">
              They shop on Markdowntoday, you bank $10 bucks.
            </p>
            <button className="bg-[#ED3A58] btn btn-primary hover:bg-red-600">
              Join Markdowntoday & Start Referring
            </button>
          </div>
        </div>
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            background:
              "linear-gradient(to bottom right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8))",
          }}
        ></div>
      </section>

      <div className="flex justify-center items-center my-28">
        <div className="w-[300PX] h-40 bg-gradient-to-tr from-yellow-400 to-red-500 rounded-md p-8">
          <div className="text-center mt-3">
            <h2 className="text-2xl font-bold text-white">01</h2>
            <p className="text-white mt-1">Refer your squad</p>
          </div>
        </div>
        <div className="w-[300PX] h-40 bg-gradient-to-tr from-pink-400 to-purple-500 rounded-md p-8 ml-8">
          <div className="text-center mt-3">
            <h2 className="text-2xl font-bold text-white">02</h2>
            <p className="text-white mt-1">They sign up and shop</p>
          </div>
        </div>
        <div className="w-[300PX] h-40 bg-gradient-to-tr from-blue-400 to-green-500 rounded-md p-8 ml-8">
          <div className="text-center mt-3">
            <h2 className="text-2xl font-bold text-white">03</h2>
            <p className="text-white mt-1">You get $10 in your account</p>
          </div>
        </div>
      </div>

      <section
        className="bg-hero bg-cover bg-center h-screen relative"
        style={{
          backgroundImage: `url(${ref1})`,
        }}
      >
        <div
          className="container mx-auto h-full flex items-center justify-center"
          style={{
            position: "relative",
            zIndex: "1",
          }}
        >
          <div className="max-w-4xl px-6 text-center">
          <p className="text-green-600 text-lg font-semibold">MDT HAPPY CASH</p>
            <h1 className="text-2xl md:text-4xl text-gray-800 font-bold mb-4 mt-4">
              Win $500 cash every month!
            </h1>
            <p className="text-lg md:text-xl text-gray-800 mb-8">
              Sign up today, confirm your email and maybe you’ll be our next
              winner!
            </p>
            <button className="bg-[#ED3A58] btn btn-primary rounded-full border-0 px-10 hover:bg-red-600">
              Let's Win!
            </button>
          </div>
        </div>
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            background:
              "linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8))",
          }}
        ></div>
      </section>
    </div>
  );
};

export default Referral;
