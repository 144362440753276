import React, { useState, useRef, useEffect } from "react";
import {
  HiOutlineQuestionMarkCircle,
  HiOutlineCash,
  HiOutlineClock,
  HiOutlineUsers,
  HiOutlineChatAlt2,
  HiOutlinePhone,
  HiOutlineSupport,
} from "react-icons/hi";
import FAQAccordion from "../FAQAccordion/FAQAccordion";
import SupportTicket from "../SupportTicket/SupportTicket";
import MissingCashBack from "../MissingCashBack/MissingCashBack";
import { useNavigate } from "react-router-dom";
import PayoutHistory from "../PayoutHistory/PayoutHistory";
import CashBackWork from "../CashBackWork/CashBackWork";
import HighestCashBackGuarantee from "../HighestCashBackGuarantee/HighestCashBackGuarantee";
import ContactUs from "../ContactUs/ContactUs";

const HelpHome = () => {
  document.title = "Help - Markdowntoday";
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/referral");
  };

  const [showFAQs, setShowFAQs] = useState(false);
  const [showSupportTicket, setShowSupportTicket] = useState(false);
  const [showMissingCashBack, setShowMissingCashBack] = useState(false);
  const [showPayoutHistory, setPayoutHistory] = useState(false);
  const [showCashBackWork, setCashBackWork] = useState(false);
  const [showHighestCashBackGuarantee, setHighestCashBackGuarantee] = useState(false);
  const [showContactUs, setContactUs] = useState(false);

  const faqRef = useRef(null);
  const SupportTicketRef = useRef(null);
  const MissingCashBackRef = useRef(null); // Add a reference for the MissingCashBack component
  const PayoutHistoryRef = useRef(null); // Add a reference for the MissingCashBack component
  const CashBackWorkRef = useRef(null); // Add a reference for the MissingCashBack component
  const HighestCashBackGuaranteeRef = useRef(null); // Add a reference for the MissingCashBack component
  const ContactUsRef = useRef(null); // Add a reference for the MissingCashBack component

  const toggleFAQs = () => {
    setShowFAQs((prevShowFAQs) => !prevShowFAQs);
    setShowSupportTicket(false); // Close the SupportTicket component when FAQs is clicked
    setShowMissingCashBack(false); // Close the MissingCashBack component when FAQs are clicked
    setPayoutHistory(false); // Close the PayoutHistory component when FAQs are clicked
    setCashBackWork(false); // Close the PayoutHistory component when FAQs are clicked
    setHighestCashBackGuarantee(false); // Close the PayoutHistory component when FAQs are clicked
    setContactUs(false); // Close the PayoutHistory component when FAQs are clicked
  };

  const toggleSupportTicket = () => {
    setShowSupportTicket((prevShowSupportTicket) => !prevShowSupportTicket);
    setShowFAQs(false); // Close the MissingCashBack component when SupportTicket is clicked
    setShowMissingCashBack(false); // Close the MissingCashBack component when SupportTicket is clicked
    setPayoutHistory(false); // Close the MissingCashBack component when SupportTicket is clicked
    setCashBackWork(false); // Close the MissingCashBack component when SupportTicket is clicked
    setHighestCashBackGuarantee(false);
    setContactUs(false); // Close the MissingCashBack component when SupportTicket is clicked
  };

  const toggleMissingCashBack = () => {
    setShowMissingCashBack(
      (prevShowMissingCashBack) => !prevShowMissingCashBack
    );
    setShowFAQs(false); // Close the MissingCashBack component when SupportTicket is clicked
    setShowSupportTicket(false); // Close the MissingCashBack component when SupportTicket is clicked
    setPayoutHistory(false); // Close the MissingCashBack component when SupportTicket is clicked
    setCashBackWork(false); // Close the MissingCashBack component when SupportTicket is clicked
    setHighestCashBackGuarantee(false);
    setContactUs(false); // Close the MissingCashBack component when SupportTicket is clicked
  };

  const togglePayoutHistory = () => {
    setPayoutHistory(
      (prevPayoutHistory) => !prevPayoutHistory
    );
    setShowFAQs(false);
    setShowSupportTicket(false); // Close the MissingCashBack component when SupportTicket is clicked
    setShowMissingCashBack(false); // Close the MissingCashBack component when SupportTicket is clicked
    setCashBackWork(false); // Close the MissingCashBack component when SupportTicket is clicked
    setHighestCashBackGuarantee(false);
    setContactUs(false); // Close the MissingCashBack component when SupportTicket is clicked
  };
  
  const toggleCashBackWork = () => {
    setCashBackWork(
      (prevCashBackWork) => !prevCashBackWork
    );
    setShowFAQs(false);
    setShowSupportTicket(false); // Close the MissingCashBack component when SupportTicket is clicked
    setShowMissingCashBack(false); // Close the MissingCashBack component when SupportTicket is clicked
    setPayoutHistory(false); // Close the MissingCashBack component when SupportTicket is clicked
    setHighestCashBackGuarantee(false);
    setContactUs(false); // Close the MissingCashBack component when SupportTicket is clicked
  };

  const toggleHighestCashBackGuarantee = () => {
    setHighestCashBackGuarantee(
      (prevHighestCashBackGuarantee) => !prevHighestCashBackGuarantee
    );
    setShowFAQs(false);
    setShowSupportTicket(false); // Close the MissingCashBack component when SupportTicket is clicked
    setShowMissingCashBack(false); // Close the MissingCashBack component when SupportTicket is clicked
    setPayoutHistory(false); // Close the MissingCashBack component when SupportTicket is clicked
    setCashBackWork(false);
    setContactUs(false); // Close the MissingCashBack component when SupportTicket is clicked
  };
  const toggleContactUs = () => {
    setContactUs(
      (prevContactUs) => !prevContactUs
    );
    setShowFAQs(false);
    setShowSupportTicket(false); // Close the MissingCashBack component when SupportTicket is clicked
    setShowMissingCashBack(false); // Close the MissingCashBack component when SupportTicket is clicked
    setPayoutHistory(false); // Close the MissingCashBack component when SupportTicket is clicked
    setCashBackWork(false); // Close the MissingCashBack component when SupportTicket is clicked
    setHighestCashBackGuarantee(false); // Close the MissingCashBack component when SupportTicket is clicked
  };

  useEffect(() => {
    if (showFAQs) {
      faqRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showFAQs]);

  useEffect(() => {
    if (showSupportTicket) {
      SupportTicketRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showSupportTicket]);

  useEffect(() => {
    if (showMissingCashBack) {
      MissingCashBackRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showMissingCashBack]);

  useEffect(() => {
    if (showPayoutHistory) {
      PayoutHistoryRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showPayoutHistory]);
  
  useEffect(() => {
    if (showCashBackWork) {
      CashBackWorkRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showCashBackWork]);

  useEffect(() => {
    if (showHighestCashBackGuarantee) {
      HighestCashBackGuaranteeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showHighestCashBackGuarantee]);

  return (
    <div className="container mx-auto my-7 md:px-20">
      <h1 className="text-3xl font-bold text-center mb-8">
        How can we help you?
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* View Our FAQs */}
        <div
          className={`bg-base-200 rounded-lg shadow-lg p-6 flex items-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
            showFAQs ? "bg-blue-100" : ""
          }`}
          onClick={toggleFAQs}
        >
          <div className="text-blue-500 mr-4">
            <HiOutlineQuestionMarkCircle size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">View Our FAQs</h2>
            <p className="text-gray-600">
              Find answers to commonly asked questions.
            </p>
          </div>
        </div>

        {/* Missing Cash Back? */}
        <div
          className={`bg-base-200 rounded-lg shadow-lg p-6 flex items-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
            showMissingCashBack ? "bg-blue-100" : ""
          }`}
          onClick={toggleMissingCashBack}
        >
          <div className="text-blue-500 mr-4">
            <HiOutlineCash size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">Missing Cash Back?</h2>
            <p className="text-gray-600">
              Learn what to do if you're missing cash back.
            </p>
          </div>
        </div>

        {/* Payout History */}
        <div
          className={`bg-base-200 rounded-lg shadow-lg p-6 flex items-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
            showPayoutHistory ? "bg-blue-100" : ""
          }`}
          onClick={togglePayoutHistory}
        >
          <div className="text-blue-500 mr-4">
            <HiOutlineClock size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">Payout History</h2>
            <p className="text-gray-600">
              Track your cash back payout history.
            </p>
          </div>
        </div>

        {/* My Referrals */}
        <div
          className="bg-base-200 rounded-lg shadow-lg p-6 flex items-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
          onClick={handleClick}
        >
          <div className="text-blue-500 mr-4">
            <HiOutlineUsers size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">My Referrals</h2>
            <p className="text-gray-600">Learn about our referral program.</p>
          </div>
        </div>

        {/* How Does Markdowntoday Cash Back Work? */}
        <div
          className={`bg-base-200 rounded-lg shadow-lg p-6 flex items-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
            showCashBackWork ? "bg-blue-100" : ""
          }`}
          onClick={toggleCashBackWork}
        >
          <div className="text-blue-500 mr-4">
            <HiOutlineQuestionMarkCircle size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">
              How Does Markdowntoday Cash Back Work?
            </h2>
            <p className="text-gray-600">
              Understand how our cash back system works.
            </p>
          </div>
        </div>

        {/* Highest Cash Back Guarantee */}
        <div
          className={`bg-base-200 rounded-lg shadow-lg p-6 flex items-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
            showHighestCashBackGuarantee ? "bg-blue-100" : ""
          }`}
          onClick={toggleHighestCashBackGuarantee}
        >
          <div className="text-blue-500 mr-4">
            <HiOutlineCash size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">
              Highest Cash Back Guarantee
            </h2>
            <p className="text-gray-600">
              Learn about our cash back guarantee.
            </p>
          </div>
        </div>

        {/* Live Chat */}
        <div className="bg-base-200 rounded-lg shadow-lg p-6 flex items-center">
          <div className="text-blue-500 mr-4">
            <HiOutlineChatAlt2 size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">Live Chat</h2>
            <p className="text-gray-600">
              Chat with our support team in real-time.
            </p>
          </div>
        </div>

        {/* Contact Us */}
        <div
          className={`bg-base-200 rounded-lg shadow-lg p-6 flex items-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
            showContactUs ? "bg-blue-100" : ""
          }`}
          onClick={toggleContactUs}
        >
          <div className="text-blue-500 mr-4">
            <HiOutlinePhone size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">Contact Us</h2>
            <p className="text-gray-600">
              Get in touch with our customer support.
            </p>
          </div>
        </div>

        {/* Support Ticket */}
        <div
          className={`bg-base-200 rounded-lg shadow-lg p-6 flex items-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${
            showSupportTicket ? "bg-blue-100" : ""
          }`}
          onClick={toggleSupportTicket}
        >
          <div className="text-blue-500 mr-4">
            <HiOutlineSupport size={36} />
          </div>
          <div>
            <h2 className="text-lg font-semibold">Support Ticket</h2>
            <p className="text-gray-600">
              Send a ticket to us to get quick support.
            </p>
          </div>
        </div>
      </div>

      {/* Render FAQAccordion if showFAQs is true */}
      {showFAQs && !showSupportTicket && (
        <div className="mt-8" ref={faqRef}>
          <FAQAccordion />
        </div>
      )}
      {/* Render SupportTicket if showSupportTicket is true */}
      {showSupportTicket && !showFAQs && (
        <div className="mt-8" ref={SupportTicketRef}>
          <SupportTicket />
        </div>
      )}
      {/* Render MissingCashBack if showMissingCashBack is true */}
      {showMissingCashBack && (
        <div className="mt-8" ref={MissingCashBackRef}>
          <MissingCashBack />
        </div>
      )}
      {/* Render PayoutHistory if showPayoutHistory is true */}
      {showPayoutHistory && (
        <div className="mt-8" ref={PayoutHistoryRef}>
          <PayoutHistory />
        </div>
      )}
      {/* Render PayoutHistory if showPayoutHistory is true */}
      {showCashBackWork && (
        <div className="mt-8" ref={CashBackWorkRef}>
          <CashBackWork />
        </div>
      )}
      {/* Render PayoutHistory if showPayoutHistory is true */}
      {showHighestCashBackGuarantee && (
        <div className="mt-8" ref={HighestCashBackGuaranteeRef}>
          <HighestCashBackGuarantee />
        </div>
      )}
      {/* Render PayoutHistory if showPayoutHistory is true */}
      {showContactUs && (
        <div className="mt-8" ref={ContactUsRef}>
          <ContactUs />
        </div>
      )}
    </div>
  );
};

export default HelpHome;
