import React from "react";
import job1 from "../../Assets/jobs/job1.jpg";
import { Link } from "react-router-dom";


const Job = () => {
    document.title = "Markdowntoday Jobs";
    return(
        <div className="mx-auto">
            <div className="hero bg-[#ebaeae] dark:bg-white py-10 justify-start md:pl-20">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={job1} className="md:max-w-md rounded-sm shadow-2xl" />
                    <div>
                    <h1 className="text-4xl font-bold">Join One of the Best Places to Work.</h1>
                    <p className="py-6">Discover what Prodege has to offer. As a dynamic, fast-growing company, we’re seeking peoplewith a wide range of skill sets.</p>
                    <Link to="/apply" className="p-3 rounded-sm text-white font-bold bg-[#ED3A58]">Apply Now</Link>
                    </div>
                </div>
            </div>

            {/* <div className="hero min-h-[60vh] bg-base-200">
                <div className="hero-content text-center">
                    <div className="max-w-[70%]">
                    <h1 className="text-4xl font-bold">What Our Team Has to Say</h1>
                    <p className="py-6">Looking for a company that not only embraces our Core Values but exemplifies them day in and day out? Hear directly from the team about working at Markdowntoday.</p>
                    </div>
                </div>
            </div>


            <div className="card card-side bg-base-100 shadow-xl">
                <figure><img src={job2} alt="Movie"/></figure>
                <div className="card-body">
                    <h2 className="card-title">New movie is released!</h2>
                    <p>Click the button to watch on Jetflix app.</p>
                    <div className="card-actions justify-end">
                    <button className="btn btn-primary">Watch</button>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Job;