import React from "react";

const Terms = () => {
  return (
    <div className="px-6 py-2 md:px-40 md:py-10">
      <div>
        <h1 className="font-semibold text-2xl mb-10">
          MARKDOWNTODAY.COM TERMS AND CONDITIONS
        </h1>
      </div>
      <p>
        Markdowntoday.com and its parent entity Carociba Inc. reserves the right
        to amend these Terms and Conditions, Coupon and Deal policies, Cash Back
        and Affiliate terms at any time without notice.
      </p>

      <div className="mt-3">
        <h2 className="font-semibold">Overview</h2>
      </div>
      <p>
        Markdowntoday.com is owned by Carociba Inc. Use of the services provided
        by Markdowntoday.com is regulated by the following terms and conditions.
        By using these services, users consent and agree to these terms and
        conditions. If a user finds the terms and conditions unacceptable, they
        should discontinue use immediately.
      </p>

      <div className="mt-3">
        <h2 className="font-semibold">Services</h2>
      </div>
      <p>
        Markdowntoday.com does not offer any warranties for the products,
        promotions, coupon codes, and special offers being provided by
        participating retailers and advertisers. We do not control these
        retailers, do not endorse them, and are not responsible for any aspects
        of their websites or businesses. Retailers can at any time alter or
        cancel any and all coupons/promotions. Our service involves publishing
        links to online retailers as well as printable coupons redeemable at
        brick-and-mortar establishments. The primary purpose of Markdowntoday.com
        is to help members and shoppers save money on all their purchases via
        Cash Back, Coupons, hand-picked deals, money-saving tools, affiliate
        programs, and more.
      </p>

      <div className="mt-3">
        <h2 className="font-semibold">Advertiser Disclosure</h2>
      </div>
      <p>
        Markdowntoday.com partners with thousands of retail stores to offer you
        the latest money-saving opportunities. When you follow a link on
        Markdowntoday.com to visit a store, Markdowntoday.com may earn a
        commission on purchases made during that store visit. We then share the
        majority of our earned commission with you, our members, as Cash Back.
      </p>

      <div className="mt-3">
        <h2 className="font-semibold">Rules of Contests</h2>
      </div>
      <p>
        Listed below are the rules that apply to any and all contests promoted
        by Markdowntoday.com:
      </p>
      <ul>
        <li>Winners are selected at random</li>
        <li>No purchase is necessary to enter or win Markdowntoday.com contests</li>
        <li>Contests are open only to United States residents who are 18 years of age or older</li>
        <li>Requests for substitutions of prizes will not be honored</li>
        <li>
          All winners are solely responsible for any and all taxes and/or fees,
          and all such additional costs
        </li>
        <li>
          Markdowntoday.com will not be held responsible for defective products
          delivered to contest winners
        </li>
        <li>
        Winners of contests will have their names and/or photos used for promotional purposes by Markdowntoday.com unless specified otherwise
        </li>
        <li>
          Markdowntoday.com reserves the right to disqualify any participant or winner suspected of fraudulent activity or violating these terms and conditions
        </li>
      </ul>

      <div className="mt-3">
        <h2 className="font-semibold">Intellectual Property</h2>
      </div>
      <p>
        All content on Markdowntoday.com, including but not limited to text, graphics, logos, images, and software, is the property of Markdowntoday.com or its content suppliers and is protected by international copyright laws.
      </p>

      <div className="mt-3">
        <h2 className="font-semibold">Disclaimer</h2>
      </div>
      <p>
        Markdowntoday.com provides its services on an "as-is" basis and makes no representations or warranties of any kind, express or implied, including but not limited to the accuracy, reliability, suitability, or availability of the services. Markdowntoday.com disclaims all warranties, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>

      <div className="mt-3">
        <h2 className="font-semibold">Limitation of Liability</h2>
      </div>
      <p>
        In no event shall Markdowntoday.com or its affiliates be liable for any indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the services or the content provided on Markdowntoday.com.
      </p>

      <div className="mt-3">
        <h2 className="font-semibold">Governing Law</h2>
      </div>
      <p>
        These terms and conditions shall be governed by and construed in accordance with the laws of the state of New York, without regard to its conflict of laws principles.
      </p>

      <div className="mt-3">
        <h2 className="font-semibold">Contact Information</h2>
      </div>
      <p>
        If you have any questions or concerns about these terms and conditions, please contact us at Info@carociba.com.
      </p>
    </div>
  );
};

export default Terms;
