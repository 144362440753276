import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Logo from "../../Assets/logo.png";
import { AuthContext } from "../../contexts/AuthProvider";
import { BASE_URL } from "../../api/api";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import axios from "axios";

const Signup = () => {

  const { logOut } = useContext(AuthContext);

  // Function to clear localStorage
  const clearLocalStorage = () => {
    localStorage.clear();
  };

  const handleLogOut = async () => {
    try {
      clearLocalStorage(); // Clear localStorage before logout
      await logOut();
      toast.success("Successfully logged out!");
    } catch (err) {
      console.log(err);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { createUser, updateUser, verifyEmail, googleSignIn, facebookSignIn } =
    useContext(AuthContext);
  const [signupError, setSignupError] = useState("");
  const [redirectToLogin, setRedirectToLogin] = useState(false); // Add state for redirection

  const navigate = useNavigate();

  const handleSignup = async (data) => {
    console.log(data);
    setSignupError("");

    try {
      // Create user in Firebase Authentication
      const result = await createUser(data.email, data.password);
      const user = result.user;
      console.log(user);

      const userInfo = {
        displayName: data.name,
      };

      // Verify email
      await verifyEmail();
      toast.success("Your account has been created!");

      // Update user info
      await updateUser(userInfo);

      // Send data to API using Axios
      try {
        const response = await axios.post(`${BASE_URL}/user-registration`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("API Response for Web:", response.data.user);

        const apiToken = response.data.user.access_token;
        console.log("Check The API Token from Web:", apiToken);
        localStorage.setItem("access_token", apiToken);
        const accessToken = localStorage.getItem("access_token");
        console.log("Check The Access Token from Web:", accessToken);
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        console.log(response.data);

        // Redirect to login page after successful signup
        setRedirectToLogin(true);
      } catch (error) {
        if (error.response) {
          console.log("API Error Response Data:", error.response.data);
          console.log("API Error Status Code:", error.response.status);
        }
        throw new Error("Failed to send data to API");
      }
    } catch (error) {
      console.log(error);
      setSignupError(error.message);
    }
  };

  const handleGoogleSignIn = () => {
    googleSignIn()
      .then((result) => {
        const user = result.user;
        console.log(user);
        window.location.reload(); // Reload the page
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const handleFacebookSignIn = () => {
    facebookSignIn()
      .then((result) => {
        const user = result.user;
        console.log(user);
        window.location.reload(); // Reload the page
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  // Redirect to login page if redirectToLogin is true
  if (redirectToLogin) {
    handleLogOut();
    return <Navigate to="/login" />;
  }
  return (
    <div className="">
      <form onSubmit={handleSubmit(handleSignup)}>
        <p>Name</p>
        <input
          type="text"
          {...register("name", {
            required: "Username is required!",
          })}
          className="bg-gray-50 mb-2 rounded shadow-inner placeholder:text-center h-8 focus:outline-blue-800 border-[1px] border-gray-800 w-full"
        />
        {errors.name && <p className="text-red-500">{errors.name.message}</p>}
        <p>Phone</p>
        <input
          type="phone"
          {...register("phone", { required: "Phone is required!" })}
          className="bg-gray-50 mb-2 rounded shadow-inner placeholder:text-center h-8 focus:outline-blue-800 border-[1px] border-gray-800 w-full"
        />
        {errors.phone && <p className="text-red-500">{errors.phone.message}</p>}
        <p>Email</p>
        <input
          type="email"
          {...register("email", { required: "Email is required!" })}
          className="bg-gray-50 mb-2 rounded shadow-inner placeholder:text-center h-8 focus:outline-blue-800 border-[1px] border-gray-800 w-full"
        />
        {errors.email && <p className="text-red-500">{errors.email.message}</p>}
        <p>Password</p>
        <input
          type="password"
          {...register("password", {
            required: "Password is required!",
            minLength: {
              value: 8,
              message: "Password must be 8 characters or longer",
            },
          })}
          className="bg-gray-50 rounded shadow-inner placeholder:text-center h-8 focus:outline-blue-800 border-[1px] border-gray-800 w-full"
        />
        {errors.password && <p className="text-red-500">{errors.password.message}</p>}
        <p>Have a Referral Code?</p>
        <input
          type="number"
          {...register("referral_code")}
          className="bg-gray-50 mb-2 rounded shadow-inner placeholder:text-center h-8 focus:outline-blue-800 border-[1px] border-gray-800 w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        />
        <button
          type="submit"
          className="h-8 w-full loginButton mt-2 rounded text-white text-center"
        >
          Join
        </button>
        <ToastContainer />
      </form>
      <Link onClick={handleGoogleSignIn}>
        <div className="h-10 w-full px-2  flex justify-start pl-14 gap-4 items-center bg-[#E3F1FE] mt-2 rounded  border-[1px] border-[#207198] cursor-pointer shadow-sm hover:shadow-lg">
          <FcGoogle className="text-lg"></FcGoogle>
          <h2 className="text-sm text-[#207198]">Continue With Google</h2>
        </div>
      </Link>
      <Link onClick={handleFacebookSignIn}>
        <div className="h-10 w-full px-2 flex justify-start gap-4 pl-14 items-center bg-[#E3F1FE] mt-4 rounded  border-[1px] border-[#207198] cursor-pointer shadow-sm hover:shadow-lg">
          <BsFacebook className="text-lg"></BsFacebook>
          <h2 className="text-sm text-[#207198]">Continue With Facebook</h2>
        </div>
      </Link>
      {signupError && <p className="text-red-500">{signupError}</p>}
    </div>
  );
};

export default Signup;
