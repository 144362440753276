import React from 'react';
import FAQAccordion from '../FAQAccordion/FAQAccordion';

const FAQ = () => {
    return (
        <div className='p-6 md:p-20'>
            <FAQAccordion></FAQAccordion>
        </div>
    );
};

export default FAQ;